import React from 'react'
import whoWeAre1 from '../assets/who-we-are-01.jpg'

const WhoWeAre = () => {
  return (
    <section className='who-we-are container' id='about'>
      <div className='who-we-are-content'>
        <h2>Who We Are</h2>
        <p>
          We are a team of passionate tech experts dedicated to providing
          innovative solutions that help businesses thrive in the digital age.
          Our mission is to empower companies with the tools and knowledge they
          need to stay ahead of the competition and achieve their goals.
        </p>
        <button className='get-started-button'>Get Started</button>
      </div>
      <div className='who-we-are-images'>
        <img src={whoWeAre1} alt='Team working' className='who-we-are-image' />
        <img src={whoWeAre1} alt='Tech meeting' className='who-we-are-image' />
      </div>
    </section>
  )
}

export default WhoWeAre
