import React from 'react'
import { TbCloudComputing } from 'react-icons/tb'
import { IoAnalyticsOutline } from 'react-icons/io5'
import { SiCyberdefenders } from 'react-icons/si'

const Services = () => {
  return (
    <section className='services container' id='services'>
      <h2>Our Expertise</h2>
      <div className='service-cards'>
        <div className='service-card'>
          <TbCloudComputing className='icon' />
          <h3>Cloud Services</h3>
          <p>
            Leverage the power of the cloud to improve scalability, reliability,
            and cost-efficiency of your IT infrastructure.
          </p>
        </div>
        <div className='service-card'>
          <IoAnalyticsOutline className='icon' />
          <h3>Data Analytics</h3>
          <p>
            Transform your data into actionable insights to drive strategic
            decision-making and business growth.
          </p>
        </div>
        <div className='service-card'>
          <SiCyberdefenders className='icon' />
          <h3>Cybersecurity</h3>
          <p>
            Protect your business from cyber threats with our comprehensive
            security solutions and proactive monitoring.
          </p>
        </div>
      </div>
    </section>
  )
}

export default Services
