import React from 'react'
import './App.css'
import Header from './components/Header'
import Hero from './components/Hero'
import Services from './components/Services'
import WhoWeAre from './components/WhoWeAre'
import FAQ from './components/FAQ'
import ContactUs from './components/ContactUs'
import Footer from './components/Footer'

function App() {
  return (
    <div className='App'>
      <Header />
      <Hero />
      <Services />
      <WhoWeAre />
      <FAQ />
      <ContactUs />
      <Footer />
    </div>
  )
}

export default App
