import React from 'react'
import { CiFacebook, CiTwitter, CiLinkedin } from 'react-icons/ci'

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer-content  container'>
        <div className='footer-section'>
          <h3>About Us</h3>
          <p>
            We are a tech consulting firm dedicated to providing innovative
            solutions to help businesses thrive in the digital age.
          </p>
        </div>
        <div className='footer-section'>
          <h3>Contact</h3>
          <p>address: Michalaki Paridi, 4 Tersefanou, 7562, Larnaca, Cyprus</p>
          <p>Email: info@serevelt.com</p>
          <p>Phone: +357 99152561</p>
        </div>
        <div className='footer-section'>
          <h3>Follow Us</h3>
          <div className='social-icons'>
            <a href='/' target='_blank' rel=''>
              <CiFacebook />
            </a>
            <a href='/' target='_blank' rel=''>
              <CiTwitter />
            </a>
            <a href='/' target='_blank' rel=''>
              <CiLinkedin />
            </a>
          </div>
        </div>
      </div>
      <div className='footer-bottom'>
        <p>&copy; 2024 Tech Consulting. All rights reserved.</p>
      </div>
    </footer>
  )
}

export default Footer
