import React, { useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'

const Header = () => {
  const [isMobile, setIsMobile] = useState(false)

  return (
    <header className='header'>
      <div className='header-wraper container'>
        <a className='logo' href='/'>
          SEREVELT CY LTD
        </a>
        <nav className={isMobile ? 'nav-links-mobile' : 'nav-links'}>
          <a href='#home' className='nav-link'>
            Home
          </a>
          <a href='#services' className='nav-link'>
            Services
          </a>
          <a href='#about' className='nav-link'>
            About Us
          </a>
          <a href='#contact' className='nav-link'>
            Contact
          </a>
          <a href='#contact' className='free-quote-button'>
            Free Quote
          </a>
        </nav>
        <button
          className='mobile-menu-icon'
          onClick={() => setIsMobile(!isMobile)}>
          {isMobile ? <FaTimes /> : <FaBars />}
        </button>
      </div>
    </header>
  )
}

export default Header
