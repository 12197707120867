import React from 'react'

const ContactUs = () => {
  const handleSubmit = e => {
    e.preventDefault()
    console.log('submit')
  }
  return (
    <section className='contact-us' id='contact'>
      <h2>Contact Us</h2>
      <div className='contact-form-container'>
        <form className='contact-form' onSubmit={handleSubmit}>
          <div className='form-group'>
            <label htmlFor='name'>Name</label>
            <input type='text' id='name' name='name' required />
          </div>
          <div className='form-group'>
            <label htmlFor='email'>Email</label>
            <input type='email' id='email' name='email' required />
          </div>
          <div className='form-group'>
            <label htmlFor='message'>Message</label>
            <textarea id='message' name='message' rows='5' required></textarea>
          </div>
          <button type='submit' className='submit-button'>
            Submit
          </button>
        </form>
      </div>
    </section>
  )
}

export default ContactUs
