import React, { useState } from 'react'
import faqImg from '../assets/faq.jpg'
import { FaPlus, FaMinus } from 'react-icons/fa'

const FAQ = () => {
  const [openFAQ, setOpenFAQ] = useState(null)

  const toggleFAQ = index => {
    setOpenFAQ(openFAQ === index ? null : index)
  }

  const faqs = [
    {
      question: 'How can we help your business grow?',
      answer:
        'We provide a range of services including technology consulting, data analytics, and cloud solutions to help your business grow and succeed.',
    },
    {
      question: 'What makes your services unique?',
      answer:
        'Our team of experts is dedicated to delivering customized solutions tailored to your specific business needs, ensuring that you get the most out of our services.',
    },
    {
      question: 'Do you offer support after project completion?',
      answer:
        'Yes, we offer ongoing support and maintenance to ensure your technology continues to meet your business needs.',
    },
    {
      question: 'What industries do you specialize in?',
      answer:
        'We specialize in a variety of industries including finance, healthcare, retail, and technology, providing customized solutions that address the unique challenges of each sector.',
    },
    {
      question: 'How do we get started with your services?',
      answer:
        "Getting started is easy! Simply contact us through our website or give us a call. We'll discuss your needs and create a tailored plan to help your business achieve its goals.",
    },
  ]

  return (
    <section className='faq container' id='faq'>
      <div className='faq-content'>
        <h2>Frequently Asked Questions</h2>
        <div className='faq-questions'>
          {faqs.map((faq, index) => (
            <div key={index} className='faq-item'>
              <div className='faq-question' onClick={() => toggleFAQ(index)}>
                {faq.question}
                <span className='faq-icon'>
                  {openFAQ === index ? <FaMinus /> : <FaPlus />}
                </span>
              </div>
              {openFAQ === index && (
                <div className='faq-answer'>{faq.answer}</div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className='faq-img'>
        <img src={faqImg} alt='faq' />
      </div>
    </section>
  )
}

export default FAQ
