import React from 'react'
import hero1 from '../assets/hero-01.jpg'
import { FaQuoteLeft } from 'react-icons/fa'

const Hero = () => {
  return (
    <section className='hero container' id='home'>
      <div className='hero-content'>
        <h1>Connecting Businesses with Technology Solutions</h1>
        <p>
          We provide secure and innovative technology solutions to drive your
          business forward.
        </p>
        <button className='get-started-button'>Get Started</button>
      </div>
      <div className='hero-image'>
        <img src={hero1} alt='Technology Solutions' />
        <div className='hero-image-text'>
          <FaQuoteLeft className='icon' />
          <p>
            The tech consultant we regularly needed which gave an affordable
            fast tech to us.
          </p>
        </div>
      </div>
    </section>
  )
}

export default Hero
